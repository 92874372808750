<template>
  <div class="jinlinOnlineApplicants">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="previous"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="任务名称" v-model="activityName" disabled />
        <v-input label="认领人姓名" v-model="searchParam.userName" />
        <v-input label="认领人电话" v-model="searchParam.mobileNum" />
      </template>
      <!-- <template #operateSlot="scope">
        <v-button
          v-if="scope.row.signInNum > 0"
          text="编辑积分"
          type="text"
          @click="toPoint(scope.row)"
        />
      </template> -->
    </v-list>
  </div>
</template>

<script>
import { jinlinDetailListUrl } from "./api.js";
import { isSignMap, isSign } from "./map";
import { createImgVNode } from "@/utils/utils.js";
export default {
  name: "jinlinOnlineApplicants",
  data() {
    return {
      isSign,
      activityCategory: undefined, // 活动类型 20-时间银行 19-幸福学堂 1-热门活动
      scheduleId: "",
      activityName: this.$route.query.name,
      searchParam: {
        id: this.$route.query.id,
        userName: "",
        mobileNum: "",
      },
      tableUrl: jinlinDetailListUrl,
      headers: [
        {
          prop: "userName",
          label: "认领人姓名",
        },
        {
          prop: "mobileNum",
          label: "认领人电话",
        },
        {
          prop: "sceneImage",
          label: "现场照片",
          formatter: (row, prop) => {
            // if (row.isSignName == 1) {
            return createImgVNode(this, row, prop);
            // } else {
            //   return "";
            // }
          },
        },
        {
          prop: "remark",
          label: "备注",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.activityCategory = Number(this.$route.query.type);
    this.$setBreadList("认领详情");
  },
  methods: {
    previous() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.jinlinOnlineApplicants {
  box-sizing: border-box;
  height: 100%;
  .create-dialog {
    ::v-deep > .el-dialog {
      width: 430px;
    }
    .wrap {
      display: flex;
      .btn {
        margin-left: 20px;
      }
    }
    .info-card {
      border-radius: 8px;
      padding: 12px;
      font-size: 14px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 10%);
      > p {
        margin-bottom: 10px;
        > span {
          display: inline-block;
          width: 80px;
          text-align: left;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
