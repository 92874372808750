//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/manageapi/activity/activityList`;
//增加|编辑活动反馈
const activityFeedbackEdit = `/gateway/hc-serve/manageapi/activity/activityFeedbackEdit`;
//获取活动详情
const activityDetail = `/gateway/hc-serve/manageapi/activity/activityDetail`;
//增加|编辑活动
const activityEdit = `/gateway/hc-serve/manageapi/activity/activityEdit`;
//活动取消
const activityCancel = `/gateway/hc-serve/manageapi/activity/activityCancel`;
//取消活动报名
const cancelActivityApply = `/gateway/hc-serve/manageapi/activity/cancelActivityApplications`;
//活动审核
const activityAudit = `/gateway/hc-serve/manageapi/activity/activityAudit`;
//获取活动参与人列表
const activityApplicantsList = `/gateway/hc-serve/manageapi/activity/getXfActivityJoinList`;
//积分规则列表
const activityPointSettingList = `/gateway/hc-serve/manageapi/activity/activityPointSettingList`;
//活动参与人导出
const exportApplicantsUrl = `/gateway/hc-serve/manageapi/activity/exportActivityApplicantsListToExcel`;
//场地列表
const areaListUrl = `/gateway/hc-serve/manageapi/activity/reservationPlaceList`;

//金邻在线列表
const jinlinListUrl = `/gateway/hc-serve/manage/assignment/getList`;

//金邻认领详情
const jinlinDetailListUrl = `/gateway/hc-serve/manage/assignment/getApplyDetails`;

//金邻在线详情
const jinlinDetailUrl = `/gateway/hc-serve/manage/assignment/getDetails`;
//新增金邻在线
const addJinlinUrl = `/gateway/hc-serve/manage/assignment/addOrUpdateInfo`;
//删除金邻在线
const deleteJinlinUrl = `/gateway/hc-serve/manage/assignment/deleteById`;
export {
  activityFeedbackEdit,
  deleteJinlinUrl,
  jinlinDetailUrl,
  addJinlinUrl,
  jinlinListUrl,
  jinlinDetailListUrl,
  getActivityListUrl,
  activityDetail,
  activityEdit,
  cancelActivityApply,
  activityApplicantsList,
  activityCancel,
  activityPointSettingList,
  activityAudit,
  exportApplicantsUrl,
  areaListUrl,
};
