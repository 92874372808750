import { mapHelper } from "@/utils/common.js";

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

const reportScenes = [
  {
    value: "1",
    checked: false,
    label: "技能培训",
  },
];
const { map: reportScenesMap, setOps: reportScenesOps } =
  mapHelper.setMap(reportScenes);

const sceneIdsList = [
  {
    value: "1",
    checked: false,
    label: "未来邻里",
  },
  {
    value: "2",
    checked: false,
    label: "未来教育",
  },
  {
    value: "3",
    checked: false,
    label: "未来健康",
  },
  {
    value: "4",
    checked: false,
    label: "未来创业",
  },
  {
    value: "5",
    checked: false,
    label: "未来建筑",
  },
  {
    value: "6",
    checked: false,
    label: "未来交通",
  },
  {
    value: "7",
    checked: false,
    label: "未来低碳",
  },
  {
    value: "8",
    checked: false,
    label: "未来服务",
  },
  {
    value: "9",
    checked: false,
    label: "未来治理",
  },
];

const { map: sceneIdsListMap, setOps: sceneIdsListOps } =
  mapHelper.setMap(sceneIdsList);

const activityStatus = [
  {
    value: "1",
    label: "报名未开始",
  },
  {
    value: "2",
    label: "报名进行中",
  },
  {
    value: "3",
    label: "活动未开始",
  },
  {
    value: "4",
    label: "活动进行中",
  },
  {
    value: "5",
    label: "活动结束",
  },
  {
    value: "6",
    label: "活动取消",
  },
];

const activityTypeList = [
  {
    value: 1,
    label: "热门活动",
  },
  {
    value: 20,
    label: "时间银行",
  },
  {
    value: 19,
    label: "幸福学堂",
  },
];

//是否签名
const isSign = [
  {
    value: "1",
    label: "是",
  },
  {
    value: "0",
    label: "否",
  },
];
const { map: isSignMap, setOps: isSignOps } = mapHelper.setMap(isSign);

//金邻在线任务状态
const jinlinStatus = [
  {
    value: "1",
    label: "认领中",
  },
  {
    value: "2",
    label: "已认领完成",
  },
];
const { map: jinlinStatusMap, setOps: jinlinStatusOps } =
  mapHelper.setMap(jinlinStatus);
export {
  jinlinStatus,
  jinlinStatusMap,
  setIsTopOps,
  activityStatus,
  activityTypeList,
  sceneIdsListMap,
  sceneIdsListOps,
  sceneIdsList,
  reportScenes,
  reportScenesMap,
  reportScenesOps,
  isSignMap,
  isSign,
};
